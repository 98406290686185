import logo from './logo.svg';
import './App.css';
import Menu from './Assets/Menu.png'
import Photo1 from './Assets/Photo1.jpg'
import Photo2 from './Assets/Photo2.jpg'
import Photo3 from './Assets/Photo3.png'
import Photo4 from './Assets/Photo4.png'
import Photo5 from './Assets/Photo5.png'
import Photo6 from './Assets/Photo6.png'
import Photo7 from './Assets/Photo7.jpg'
import Photo8 from './Assets/Photo8.png'
import { useState } from 'react';
import axios from 'axios';
import qs from 'qs'

function App() {

  let [lang, setLang] = useState(true)
  let [showMenu, setShowMenu] = useState(false)
  let [Name, setName] = useState('')
  let [Phone, setPhone] = useState('')
  let [Message, setMessage] = useState('')
  let [sendMess, setSendMess] = useState(false)
  let [badMess, setBadMess] = useState(false)

  let SendMessage = () => {
    console.log('Here')
    let Data = {
      "Name": Name,
      'Phone': Phone,
      'Message': Message,
    }
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(Data),
      url: 'https://pamian.site/ResetClientPass.php'
    }
    return axios(options).then(res => { return res.data })
  }

  return (
    <div className="App">

      <div className={showMenu ? 'Menu' : 'NoMenu'}>
        <div className='FifthTitle3' onClick={() => {
          setShowMenu(!showMenu)
          window.scrollTo({ top: document.getElementById('AboutUs').offsetTop - 54, behavior: 'smooth' })
        }}>{lang ? 'О нас' : 'About us'}</div>
        <div className='FifthTitle3' onClick={() => {
          setShowMenu(!showMenu)
          window.scrollTo({ top: document.getElementById('Delivery').offsetTop - 54, behavior: 'smooth' })
        }}>{lang ? 'Доставка' : 'Delivery'}</div>
        <div className='FifthTitle3' onClick={() => {
          setShowMenu(!showMenu)
          window.scrollTo({ top: document.getElementById('OurServices').offsetTop - 54, behavior: 'smooth' })
        }}>{lang ? 'Наши услуги' : 'Our services'}</div>
        <div className='FifthTitle3' onClick={() => {
          setShowMenu(!showMenu)
          window.scrollTo({ top: document.getElementById('Contacts').offsetTop - 54, behavior: 'smooth' })
        }}>{lang ? 'Контакты' : 'Contacts'}</div>
      </div>

      <div className='Header'>
        <div className='MenuContainer' onClick={() => setShowMenu(!showMenu)}>
          <img src={Menu} className='MenuImg'></img>
        </div>
        <div className='HeaderTitle'>Smokingman</div>
        <div className='Lang' onClick={() => setLang(!lang)}>{lang ? 'Рус' : 'En'}</div>
      </div>

      <div className='FirstBlock' onClick={() => setShowMenu(false)}>
        <div className='GreenLine'></div>
        <div className='FirstBlock1'>
          <div className='TextBlock1'>
            <div className='Title1'>{lang ? 'Магазин табачных изделий' : 'Tobacco Products Store'}</div>
            <div className='Title2'>{lang ? 'С 1999 года мы предлагаем уникальную коллекцию ароматного трубочного и сигаретного табака, сигар и аксессуаров для курения зарубежного и российского производства.' : 'Since 1999, we have been offering a unique collection of aromatic pipe and cigarette tobacco, cigars and smoking accessories of foreign and Russian production.'}</div>
          </div>
          <img src={Photo1} className='FirstBlockImg'></img>
        </div>
      </div>

      <div className='SecondBlock' onClick={() => setShowMenu(false)}>
        <img src={Photo2} className='SecondImg'></img>
        <div className='SecondBlock2'>
          <div className='SecondBlockText'>
            <div className='SecondTitle1'>Mac Baren</div>
            <div className='SecondTitle2'>{lang ? 'Мы рады предложить вам качественные табачные изделия от компании Mac Baren и аксессуары других производителей по минимальным ценам с подробными описаниями, характеристиками и фотографиями.' : 'We are pleased to offer you high-quality tobacco products from Mac Baren and accessories from other manufacturers at the lowest prices with detailed descriptions, characteristics and photos.'}</div>
          </div>
        </div>
      </div>

      <div className='ThirdBlock' id='AboutUs' onClick={() => setShowMenu(false)}>
        <div className='GreenLine'></div>
        <div className='ThirdBlock2'>
          <div className='ThidrBlockInfoContainer'>
            <div className='ThidInfoTitle'>{lang ? 'О нас' : 'About us'}</div>
            <div className='InfoLine'>
              <div className='Tire'></div>
              <div className='ThirdText'>{lang ? 'Работаем на Российском рынке с 1999 года, имеем четыре розничных магазина в г.Москва и четыре пункта выдачи товара.' : 'We have been working on the Russian market since 1999, we have four retail stores in Moscow and four points of delivery of goods.'}</div>
            </div>
            <div className='InfoLine'>
              <div className='Tire'></div>
              <div className='ThirdText'>{lang ? 'В магазинах компании Smokingman продается только легальная продукция, мы работаем только с проверенными поставщиками.' : 'Only legal products are sold in the stores of the Smoking man company, we work only with trusted suppliers.'}</div>
            </div>
            <div className='InfoLine'>
              <div className='Tire'></div>
              <div className='ThirdText'>{lang ? 'Нашей компанией выстроены долгосрочные партнерские отношения с крупнейшими российскими импортерами табачной продукции и аксессуаров.' : 'Our company has built long-term partnerships with the largest Russian importers of tobacco products and accessories.'}</div>
            </div>
          </div>
        </div>
      </div>

      <div className='FourthBlock' id='Delivery' onClick={() => setShowMenu(false)}>
        <img src={Photo3} className='DeliveryImg'></img>
        <div className='FourthBlock2'>
          <div className='TextBlock'>
            <div className='ThirdText1'>{lang ? 'Доставка' : 'Delivery'}</div>
            <div className='ThirdText2'>{lang ? 'Доставим ваш заказ в любой уголок страны и мира, осуществим подробную консультацию по товарам и поможем определиться с выбором.' : 'We will deliver your order to any corner of the country and the world, we will carry out a detailed consultation on the goods and help you decide on the choice.'}</div>
          </div>
          <div className='GreenBlock'>
            <div></div>
            <div className='BlackLine'></div>
            <div className='BlackLine'></div>
          </div>
        </div>
      </div>

      <div className='FifthBlock' id='OurServices' onClick={() => setShowMenu(false)}>
        <div className='GreenLine'></div>
        <div className='FifthBlockCont'>
          <div className='FifthBlockContContainer'>
            <div className='FifthBlockTitle'>{lang ? 'Наши услуги' : 'Our services'}</div>
            <div className='FifthBlockRow'>
              <div className='FifthTextBlock'>
                <div className='FifthTitle1'>{lang ? 'Оборудование сигарных комнат' : 'Cigar room equipment'}</div>
                <div className='FifthTitle2'>{lang ? 'Установка и наладка систем автоматического поддерживания влажности.' : 'Installation and adjustment of automatic humidity maintenance systems.'}</div>
              </div>
              <img src={Photo4} className='FifthImg'></img>
            </div>

            <div className='FifthBlockRow' onClick={() => setShowMenu(false)}>
              <div className='FifthTextBlock'>
                <div className='FifthTitle1'>{lang ? 'Ремонт кальнов' : 'Hookah repair'}</div>
                <div className='FifthTitle2'>{lang ? 'Чистка, пайка шахт, замена уплотнителей, подбор и склеивание колб, замена и ремонт шлангов.' : 'Cleaning, soldering of mines, replacement of seals, selection and gluing of flasks, replacement and repair of hoses.'}</div>
              </div>
              <img src={Photo5} className='FifthImg'></img>
            </div>

            <div className='FifthBlockRow' onClick={() => setShowMenu(false)}>
              <div className='FifthTextBlock'>
                <div className='FifthTitle1'>{lang ? 'Ремонт электронных сигарет' : 'Repair of electronic cigarettes'}</div>
                <div className='FifthTitle2'>{lang ? 'Ремонт электронных сигарет: батарейных модов, боксмодов с платой и зарядных устройств.' : 'Repair of electronic cigarettes: battery mods, box mods with a board and chargers.'}</div>
              </div>
              <img src={Photo6} className='FifthImg'></img>
            </div>
          </div>
        </div>
        <div className='BlackLine'></div>
        <div className='BlackLine'></div>
      </div>

      <div className='SixthBlock' onClick={() => setShowMenu(false)}>
        <div className='SixthTitle'>{lang ? 'Почему выбираю Smokingman?' : 'Why choose Smokingman?'}</div>
        <div className='InfoLine'>
          <div className='Tire2'></div>
          <div className='ThirdText'>{lang ? 'Быстрая доставка до двери и самовывоз в день заказа.' : 'Fast delivery to the door and pickup on the day of the order.'}</div>
        </div>
        <div className='InfoLine'>
          <div className='Tire2'></div>
          <div className='ThirdText'>{lang ? 'Наши пункты выдачи товаров в шаговой доступности.' : 'Our pick-up points are within walking distance.'}</div>
        </div>
        <div className='InfoLine'>
          <div className='Tire2'></div>
          <div className='ThirdText'>{lang ? 'Низкие цены на табачную продукцию Mac Baren.' : 'Low prices for Mac Baren tobacco products.'}</div>
        </div>
        <div className='InfoLine'>
          <div className='Tire2'></div>
          <div className='ThirdText'>{lang ? 'Только оригинальная и сертифицированная продукция.' : 'Only original and certified products.'}</div>
        </div>
        <div className='InfoLine'>
          <div className='Tire2'></div>
          <div className='ThirdText'>{lang ? 'Доставка заказов почтой по всей России за 3-15 дней.' : 'Delivery of orders by mail throughout Russia in 3-15 days.'}</div>
        </div>
        <div className='InfoLine'>
          <div className='Tire2'></div>
          <div className='ThirdText'>{lang ? 'Выгодные акции и бонусы.' : 'Profitable promotions and bonuses.'}</div>
        </div>
      </div>

      <img src={Photo7} className='Photo7' onClick={() => setShowMenu(false)}></img>

      <div className='SeventhBlock' onClick={() => setShowMenu(false)}>
        <div className='GreenLine'></div>
        <div className='BlackLineSeventh'>
          <div>{lang ? 'С 28 января 2020 года в нельзя преобрести online никотинсодержащую продукцию, включая табачные стики и жидкости для электронных сигарет. Это связано с поправками к закону об охране здоровья граждан от воздействия окружающего табачного дыма.'
            : 'From January 28, 2020, nicotine-containing products, including tobacco sticks and liquids for electronic cigarettes, cannot be purchased online. This is due to amendments to the law on the protection of citizens health from exposure to ambient tobacco smoke.'}
            <br></br>{lang ? 'Вы можете сделать заказ на нашем сайте и забрать его в одном из наших розничных магазинов, предъявив документ удостоверяющий личность.' : 'You can place an order on our website and pick it up at one of our retail stores by presenting an identity document.'}</div>
        </div>
      </div>

      <div className='Contacts' id='Contacts' onClick={() => setShowMenu(false)}>
        <div>
          <div className='ContactTitle'>{lang ? 'Связаться с нами' : 'Contact us'}</div>
          <input className='input' value={Name} onChange={(e) => setName(e.target.value)} placeholder={lang ? 'Имя' : 'Name'}></input>
          <input className='input' value={Phone} onChange={(e) => setPhone(e.target.value)} placeholder={lang ? 'Телефон' : 'Phone'}></input>
          <textarea className='input' value={Message} onChange={(e) => setMessage(e.target.value)} placeholder={lang ? 'Сообщение' : 'Message'}></textarea>
          {sendMess ? <div className='sendMess'>{lang ? 'Сообщение отправлено!' : 'The message has been sent!'}</div> : null}
          {badMess ? <div className='sendBadMess'>{lang ? 'Введены не все поля' : 'Not all fields are entered!'}</div> : null}
          <div className='FormButton' onClick={() => {
            if (!Name || !Phone || !Message) {
              setSendMess(false)
              setBadMess(true)
              setTimeout(() => {
                setBadMess(false)
              }, 4000)
              return
            }
            SendMessage()
            setSendMess(true)
            setName('')
            setPhone('')
            setMessage('')
            setTimeout(() => {
              setSendMess(false)
            }, 4000)
          }}>{lang ? 'Отправить' : 'Send'}</div>
        </div>
        <div>
          <div className='ContactTitle'>{lang ? 'Контакты' : 'Contacts'}</div>
          <div className='ContactRow'>
            <div className='ContactText1'>{lang ? 'Можайский вал д. 10 (ТЦ Дорогомиловский, павильон Г3)' : 'Mozhaisky val, 10 (Dorogomilovsky shopping center, pavilion G3)'}</div>
            <div className='ContactText2'>{lang ? 'Телефон' : 'Phone'}: <a href='tel: +79771141002'>+7 (977) 114 10 02</a></div>
          </div>
          <div className='ContactRow'>
            <div className='ContactText1'>{lang ? 'Ул. Свободы д. 11/1' : '11/1 Svobody St.'}</div>
            <div className='ContactText2'>{lang ? 'Телефон' : 'Phone'}: <a href='tel: +79771141006'>+7 (977) 114 10 06</a></div>
          </div>
          <div className='ContactRow'>
            <div className='ContactText1'>{lang ? 'Можайское шоссе д. 31(Универмаг "Молодежный")' : 'Mozhaiskoe highway, 31(Molodezhny Department Store)'}</div>
            <div className='ContactText2'>{lang ? 'Телефон' : 'Phone'}: <a href='tel: +79771141003'>+7 (977) 114 10 03</a></div>
          </div>
          <div className='ContactRow'>
            <div className='ContactText1'>{lang ? 'Югорский проезд д. 18/14' : 'Yugorsky proezd , 18/14'}</div>
            <div className='ContactText2'>{lang ? 'Телефон' : 'Phone'}: <a href='tel: +79771141005'>+7 (977) 114 10 05</a></div>
          </div>
          <div className='Email'>Email: <a href='mailto: Smokingman@list.ru'>Smokingman@list.ru</a></div>
        </div>
      </div>

      <div className='EightBlock' onClick={() => setShowMenu(false)}>
        <div>{lang ? 'Телефон' : 'Phone'}: <a href='tel: +79261115596'>+7 926 111 55 96</a></div>
        <a href='https://wa.me/+79261115596?text=Здравствуйте%2C+у+меня+есть+вопрос'><img src={Photo8} style={{ width: '50px' }}></img></a>
      </div>

    </div>
  );
}


export default App;
